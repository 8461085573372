import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Hero from "~/components/configurable/Hero"
import Heading from "~/components/configurable/Heading"
import Block from "~/components/configurable/Block"
import BlockCTA from "~/components/configurable/BlockCTA"
import TickList from "~/components/configurable/TickList"
import { imageNodesFilter } from "~/utils"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Section from "~/components/configurable/Section"
import Col3 from "~/components/grid/Col3"
import Col9 from "~/components/grid/Col9"
const IS_HALLOWEEN = true;


const centerstyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

const Careers = ({ markdownNodes, imageNodes, pageContext: { careersItems } }) => {
  const [heroImage, setHeroImage] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const { setCustomerType } = useContext(CustomerTypeContext)

  useEffect(() => {
    // //console.log(careersItems);

    setHeroImage(imageNodesFilter(imageNodes, "careers.png"))

    setLoading(false)
  }, [imageNodes])

  return (
    <div className="homepage" style={{ opacity: loading ? 0 : 1 }}>
      {/* <Banner className="visible-xs">
        <Link to="/for-your-business">Go to Business Site</Link>
      </Banner> */}
      {IS_HALLOWEEN && !!heroImage && (
        <Hero
          className="homepage__hero"
          image={<Img fluid={heroImage.fluid} alt="For your home" />}
        >
          <Heading level={1}>
            Careers
          </Heading>
          <p style={{ fontSize: "25px" }}>Ready for a challenge?  <br /> Join the future of energy </p>
          {/* <BlockCTA url="/solar-together-london">Find Out More</BlockCTA>  */}
        </Hero>
      )}
      {!IS_HALLOWEEN && !!heroImage && (
        <Hero
          className="homepage__hero"
          image={<Img fluid={heroImage.fluid} alt="For your home" />}
          // imageUrl="/images/homepage-video.jpg"
          video="https://vimeo.com/418983793"

        >
          <Heading level={1}>
            Because not all solar <br /> is the same
          </Heading>
          <p style={{ fontSize: "25px" }}>frighteningly good deals for Halloween</p>
          <BlockCTA url="/halloween">Find Out More</BlockCTA>
        </Hero>
      )}

      <Block>
        <Section>
          <div className="container container--column illustrated-house-block">
            <Heading underlined>Join the best team</Heading>
            <p>
              Welcome to Green Energy Together Careers, where we showcase all the available jobs across the business that we are recruiting for. Explore our opportunities in your area and across the country. After finding the desired job, just email <a href="mailto:HR@get-uk.com" style={{ color: "#70b33b", fontWeight: "bold" }}>HR@get-uk.com</a> with your CV. Please use the job title you're interested in as the subject line. We look forward to welcome you to the team!
            </p>
          </div>
        </Section>
        {careersItems.map((career, index) => {
          return (
            <><div className="divider" />
              <Section>
                <div className="row">
                {index % 2 !== 0 && <Col3>
                  <Img
                fluid={career.frontmatter.list.image.childImageSharp.fluid}
                title={career.frontmatter.title}
              />
                  </Col3>}
                  <Col9>
                    <Heading underlined>{career.frontmatter.title}</Heading>
                    <p>
                      {career.frontmatter.list.description}
                    </p>
                    <TickList>
                      <li>
                      {career.frontmatter.list.responsibilty}
                      </li>
                      <li>
                        Salary: {career.frontmatter.list.salary}
                      </li>
                    </TickList>

                    <BlockCTA secondary url={`/careers${career.fields.slug}`} arrow="right">
                      Find out more
                    </BlockCTA>
                  </Col9>
                  {index % 2=== 0 && <Col3>
                    <Img
                fluid={career.frontmatter.list.image.childImageSharp.fluid}
                title={career.frontmatter.title}
              />
                  </Col3>}
                 
                </div>
              </Section>
              
              
              </>
          )
        })}
<div className="divider" />
      </Block>
    </div>
  )
}

export default Careers